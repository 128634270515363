@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0px;
}

a {
  color: #28B6FE;
}

.css-1mpbdbx {
  font-size: 18px !important;
}